import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.tsx";
import { graphql } from 'gatsby';
export const asyncComposeAndPipeFunctionsQuery = graphql`
  query AsyncComposeAndPipeFunctionsQuery($pagePath: String!) {
    mdx(frontmatter: { path: { eq: $pagePath } }) {
      frontmatter {
        path
        title
        tags
        canonical_url
        tweet
        date(formatString: "MMMM DD, YYYY")
      }
      timeToRead
      excerpt
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  asyncComposeAndPipeFunctionsQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`The functional programming inspired functions compose and pipe are awesome, but they only work with synchronous functions. I am going to show you how to write your own functions that will work with both synchronous and asynchronous functions combined, if that's your type of thing.`}</p>
    <h3>{`Cool story bro, just show me the code already!`}</h3>
    <p>{`Fine, here you go.`}</p>
    <p><strong parentName="p">{`Async compose function`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Async compose`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`compose`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`…functions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`input`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` functions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduceRight`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`chain`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` func`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` chain`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`func`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Promise`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`resolve`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`input`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Functions fn1, fn2, fn3 can be standard synchronous functions or return a Promise`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`compose`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`fn3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` fn2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` fn1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`input`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`\`Do with the `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`result`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{` as you please\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p><strong parentName="p">{`Async pipe function`}</strong></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`pipe`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`…functions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`input`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` functions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`chain`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` func`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` chain`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`func`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Promise`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`resolve`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`input`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Functions fn1, fn2, fn3 can be standard synchronous functions or return a Promise`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`await`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`fn1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` fn2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` fn3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`input`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`\`Do with the `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`result`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{` as you please\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h3>{`Right, now what?`}</h3>
    <p>{`If you came here just to get a code snippet, there is no shame in that, feel free to add it to your project and go on with your life! Otherwise, if you're interested in knowing how it works, continue reading.`}</p>
    <p>{`It might get a bit hairy but bare with me. If you are familiar with certain parts, just skip ahead.`}</p>
    <p>{`These functions require Promises to be available in your application environment, whether that is the browser or server side with Node. If you are developing for the modern web (not including IE), Promises are already supported in most browsers. Back in the real world, you will probably use a `}<a parentName="p" {...{
        "href": "https://github.com/stefanpenner/es6-promise"
      }}>{`polyfill`}</a>{` or some other third-party library, like `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/bluebird"
      }}>{`bluebird`}</a>{`, that gives you Promise support. Odds are that if you're reading this you are probably already familiar with Promises, if not, here is a quick primer.`}</p>
    <h2>{`Simplified Primer on Promises`}</h2>
    <p>{`A Promise is an object that represents an action that will finish at some time in the future. What that means, is that you create your Promise for an asynchronous action, like an AJAX request, and when it finishes, similar to using a success callback, you call its resolve function; if it fails, you call its reject function.`}</p>
    <p>{`The Promise object exposes 2 functions that map to the 2 “callback” functions, namely: .then() that will be called when you resolve the promise and .catch() when you reject it.`}</p>
    <p>{`There's loads more to Promises but if you understand that, you'll be just fine.`}</p>
    <h2>{`What's the deal with compose & pipe?`}</h2>
    <p>{`On Medium alone there are numerous articles about the intricacies of compose and pipe which does a great job at explaining it. If you're too lazy to google it right now and seeing that you are already here, I'll try and explain: composition is like Lego for grown-ups. It's a way to build something more complex by using smaller simpler units. In functional programming, compose is the mechanism that composes the smaller units (our functions) into something more complex (you guessed it, another function).`}</p>
    <p>{`So, compose takes a list of functions and returns another function that you can call with your data. An example will clear it up:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`double`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`square`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` x
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`plus3`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`x`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` composedFunction `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`compose`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  double`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  square`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  plus3
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`composedFunction`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// result = 50`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 2 (plus3) => 5 (square) => 25 (double) => 50`}</span></code></pre></div>
    <p>{`To keep things simple, we create 3 basic functions that do simple arithmetics: double, square and plus3. Nothing special here, double takes a value and multiplies it by 2; square takes a value and multiplies it with itself, and finally, plus3 does exactly that — it takes a number and adds 3 to it.`}</p>
    <p>{`On line 5, we use our compose method to create a new composedFunction that is build up of our 3 simpler functions. Each time this composedFunction is invoked it will apply these 3 functions to whatever value we pass to it. On line 7, we invoke our composedFunction with the value 2. To visualize what is happening here, picture this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`double`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`square`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`plus3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`That is ugly, but it brings the point across.`}</p>
    <p>{`The value 2, is passed into the plus3 function which produces 5. This result becomes the input for our square function, which in turn produces 25. Lastly, we invoke the double function with 25, which then produces 50.`}</p>
    <p>{`This is obviously a ridiculously simple example, but the main point is to think of complex problems as the sum of a bunch of smaller problems that you can solve and compose together.`}</p>
    <p>{`Now, imagine you have a response from an API call to retrieve a list of products, you want to map over each product and only pluck certain properties like the title, description and let's say price. Then you also want to filter out all the products that are cheaper than \\$5 and lastly sort the results alphabetically.`}</p>
    <p>{`The old you would write 3 functions: pluckProperties, getCheap and sortByTitle and do something like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` products `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// array of product objects`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`pluckProperties`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sortByTitle`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getCheap`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`products`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Bonus Tip: always filter first before doing anything else with a dataset, so that you loop through the smallest number of entries.`}</p>
    <p>{`The new you will use compose to create your reusable getProducts function:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` getProducts `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`compose`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`pluckProperties`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` sortByTitle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` getCheap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` products `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// array of product objects`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getProducts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`products`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Hopefully, you are seeing the beauty in using compose by now. If not, all hope is lost and I can't help you. Jokes. Keep thinking about it and see where it will help you write cleaner more reusable (read: better!) code in your day job.`}</p>
    <p>{`We'll quickly touch pipe as well. It is exactly the same as compose but it applies the functions from left-to-right instead of right-to-left.`}</p>
    <p>{`Using pipe, our example will look like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` getProducts `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`getCheap`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` sortByTitle`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` pluckProperties`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` products `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// array of product objects`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` result `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getProducts`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`products`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`pipe is very useful for writing step by step procedures, like:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  logUserIn`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  displayNotification`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  redirectToHomepage
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2>{`What's cool about async compose and pipe?`}</h2>
    <p>{`Traditionally, compose and pipe only works on synchronous functions that you can pass an input value, it does something with the input and then returns an output. Looking at the above pipe example, you will agree that the logUserIn function will most likely be an asynchronous function because you need some communication with a server/database. This won't work then. Time to cry in the shower.`}</p>
    <p>{`Let's fix that by using our own improved pipe function:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`pipe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  logUserIn`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  displayNotification`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  redirectToHomepage
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// The user is logged in`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// the login notification has been displayed`}</span>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// and s/he has been redirected to the homepage`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`That's it, you're done!`}</p>
    <h2>{`Let's break it down`}</h2>
    <p>{`For reference, here is the compose snippet again.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// Async compose`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`compose`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`…functions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token parameter"
          }}>{`input`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` functions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduceRight`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`chain`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` func`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` chain`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`func`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Promise`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`resolve`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`input`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// Functions fn1, fn2, fn3 can be standard synchronous functions or return a Promise`}</span>{`
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`compose`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`fn3`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` fn2`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` fn1`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`input`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`result`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` console`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`log`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`\`Do with the `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`result`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{` as you please\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`First, we see compose is a function that returns a function (example uses standard JavaScript functions):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` `}<span parentName="code" {...{
            "className": "token function-variable function"
          }}>{`compose`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}><span parentName="span" {...{
              "className": "token operator"
            }}>{`...`}</span>{`functions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`input`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ...`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`It uses the ES2015 `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions/rest_parameters"
      }}>{`rest parameters`}</a>{` to combine all the passed in functions as an array of functions.`}</p>
    <p>{`To understand the body of the inner function, it is important that you are comfortable with the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce?v=b"
      }}>{`reduce`}</a>{` and `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/ReduceRight"
      }}>{`reduceRight`}</a>{` array methods that are natively part of JavaScript.`}</p>
    <p>{`reduce loops over each item in a given array and apply a function to it, with each function's result being the input of the next item's function. Whenever you have a list of something and you want to “reduce” it to a single value, use the reduce or reduceRight methods. These methods take 2 arguments, the first is the function that needs to run for each item in the array and the second is the optional starting value.`}</p>
    <p>{`The easiest is to think of it as a SUM() or a TOTAL() method, for example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` numbers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`9`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` total `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` items`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduce`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`sum`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` number`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` sum `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` number
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// total = 15 (1 + 5 + 9)`}</span></code></pre></div>
    <p>{`The output of each function is the input of the next. For the first number in the array (1), sum will be 0 as that is the starting value and number will be 1. The second number (5) will take 1 as the value of sum and number will be 5, which produces 6. For the third number (9), sum will, therefore, be 6 and number will be 9, which produces the final output of 15.`}</p>
    <p>{`The only difference between the reduce and reduceRight is that reduceRight loops over the items in your array from right-to-left (last-to-first) instead of left-to-right (first-to-last).`}</p>
    <p>{`Okay, now that we understand reduce and Promises, let's put them together to understand the final piece of our compose function.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`functions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reduceRight`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`chain`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` func`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` chain`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`func`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Promise`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`resolve`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`input`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`Instead of looping through an array of numbers as we did in our example, here we loop over an array of functions. Instead of starting with the value 0, here we start with a Promise that immediately resolves to the value we called our composed function with.`}</p>
    <p>{`Inside our reduce/accumulator function, instead of building up a sum, we are chaining together Promises that will resolve in sequence. To visualize this using our user login example, this is what would be produced:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`logUserIn`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`displayNotification`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`redirectToHomepage`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`then`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`result`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token template-string"
          }}><span parentName="span" {...{
              "className": "token string"
            }}>{`\`Do whatever we want with the `}</span><span parentName="span" {...{
              "className": "token interpolation"
            }}><span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`\${`}</span>{`result`}<span parentName="span" {...{
                "className": "token interpolation-punctuation punctuation"
              }}>{`}`}</span></span><span parentName="span" {...{
              "className": "token string"
            }}>{`\``}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h2>{`Wrapping up`}</h2>
    <p>{`Hope you found this useful and if you want to include these functions in your project, either copy-paste it in or grab it from NPM:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.npmjs.com/package/compose-then"
        }}>{`https://www.npmjs.com/package/compose-then`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.npmjs.com/package/fp-pipe-then"
        }}>{`https://www.npmjs.com/package/fp-pipe-then`}</a></li>
    </ul>
    <p>{`If you have better ideas or something cool to share, please let me know; would love to learn from you.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      